import { createStore } from 'redux';
import { DEFAULT_VALUES, SUBSCRIPTION_MODELS } from '../constants/constants';
import { LANG } from './../i18n';
import { splitAddress } from './../services/utils';


const initialState = {
  isLoading: false,
  currentStep: 1,
  universeId: undefined,
  language: LANG,
  tokenChecked: false,

  //##################
  name: undefined, // 'Johnny',
  firstName: undefined, // 'John',
  lastName: undefined, // 'Doe',
  address: undefined, // 'Am Marktplatz 3, 54353 Bonn',
  street: undefined, // 'Am Marktplatz 3',
  postalCode: undefined, // '54353',
  city: undefined, // 'Bonn',
  country: undefined, // 'Deutschland',
  companyName: undefined, // 'JD GmbH',
  // companyName2: undefined, // 'Tochtergesellschaft der Global JD Company',
  email: undefined, // 'test000@mailinator.com',
  phone: undefined, // '0354 667 353 45-0',
  // fax: undefined, // '0354 667 353 45-1',
  password: undefined, // 'Stefan1!',
  confirmPassword: undefined, // 'Stefan1!',
  // depositorName: undefined, // 'John Doe',
  // bankName: undefined, // 'Kehlbacher Sparwutz',
  iban: undefined, // 'DE64756758456800064563',
  bic: undefined, // 'BIC77HG56',
  sepa: false,
  // ustId: undefined, // 'UST-12345',
  branchId: DEFAULT_VALUES.BRANCH_ID,
  useAdvancedBranchSelection: false,
  subscriptionId: DEFAULT_VALUES.SUBSCRIPTION_ID,
  oldSubscriptionId: 0,
  additionalUsers: 1,
  // additionalFeatures: {},
  // amountDevices: 1,
  billingPeriodId: DEFAULT_VALUES.BILLING_PERIOD,
  isExpired: false,
  isLocked: false,
  //##################

  partnerId: undefined, // 'PRTNR-2544',
  useProtectedInputField: false,
  partnerEmail: undefined, // 'partner@mailinator.com',
  voucherCode: undefined,
  orderCode: undefined,
  storeManagement: false,
  verificationLink: undefined,
  // workstationId: undefined, // 'WST-12345',
  generalConditions: false,
  eula: false,
  showFooterBar: true,
  showConfirmRegistration: false,
  showConfirmCustomerRegistration: false,
  infoTitle: undefined,
  infoContent: undefined,
  subscriptionDisabled: false,
  errors: {},
  
  // TEST DATA /////////////////////////////////////////////////////////////////
  // name: 'Homer Simpson',
  // firstName: 'Homer',
  // lastName: 'Simpson',
  // email: 'test300@mailinator.com',
  // companyName: 'Simpson GmbH',
  // street: 'Evergreen Terrace 742',
  // postalCode: '12345',
  // city: 'Springfield',
  // password: 'Stefan1!',
  // confirmPassword: 'Stefan1!',
  // branchId: 8,
  // generalConditions: true,
  // 
  // // EXTRA TEST DATA /////////
  // iban: 'DE87510500150656105151',
  // bic: 'BIC123456',
  // partnerId: '128467',
  // partnerEmail: 'partner123@mailinator.com',
  // phone: '01234 56789-0',
  //////////////////////////////////////////////////////////////////////////////
}

const getTrimmedValue = (value) => {
  return (value || '').trim() !== '' ? value : '';
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_STATE':
      return Object.assign({}, state, action.payload);
    case 'SET_LOADING':
      return Object.assign({}, state, {
        isLoading: action.payload
      });
    // case 'SET_DISABLED':
    //   return Object.assign({}, state, {
    //     allDisabled: action.payload
    //   });
    // case 'LOCK_SCREEN':
    //   return Object.assign({}, state, {
    //     isLocked: true
    //   });
    case 'TOGGLE_SHOW_FOOTER_BAR':
      return Object.assign({}, state, {
        showFooterBar: !state.showFooterBar
      });
    case 'SET_CURRENT_STEP':
      return Object.assign({}, state, {
        currentStep: action.payload
      });
    case 'SET_BRANCH_ID':
      return Object.assign({}, state, {
        branchId: action.payload
      });
    case 'TOGGLE_BRANCH_SELECTION_TYPE':
      return Object.assign({}, state, {
        useAdvancedBranchSelection: action.payload
      });
    case 'SET_NAME':
      return Object.assign({}, state, {
        name: getTrimmedValue(action.payload)
      });
    case 'SET_EMAIL':
      return Object.assign({}, state, {
        email: getTrimmedValue(action.payload)
      });
    case 'SET_PASSWORD':
      return Object.assign({}, state, {
        password: action.payload
      });
    case 'SET_CONFIRM_PASSWORD':
      return Object.assign({}, state, {
        confirmPassword: action.payload
      });
    case 'SET_GENERAL_CONDITIONS':
      return Object.assign({}, state, {
        generalConditions: action.payload
      });
    case 'SET_EULA':
      return Object.assign({}, state, {
        eula: action.payload
      });
    case 'SET_PARTNER_ID':
      return Object.assign({}, state, {
        partnerId: getTrimmedValue(action.payload)
      });
    case 'USE_PROTECTED_INPUT_FIELD':
      return Object.assign({}, state, {
        useProtectedInputField: action.payload
      });
    case 'SET_SUBSCRIPTION_ID':
      const subscriptionId = action.payload;
      const subscriptionDisabled = SUBSCRIPTION_MODELS['subscription' + subscriptionId].disable ||
      subscriptionId < state.oldSubscriptionId ||
      (!state.isExpired && subscriptionId === state.oldSubscriptionId);
      return Object.assign({}, state, {
        subscriptionId,
        subscriptionDisabled
      });
      
    case 'SET_INFO_CONTENT':
      return Object.assign({}, state, {
        infoTitle: (action.payload || {}).title,
        infoContent: (action.payload || {}).content,
      });
    case 'SET_FIRST_NAME':
      return Object.assign({}, state, {
        firstName: getTrimmedValue(action.payload)
      });
    case 'SET_LAST_NAME':
      return Object.assign({}, state, {
        lastName: getTrimmedValue(action.payload)
      });
    case 'SET_COMPANY_NAME':
      return Object.assign({}, state, {
        companyName: getTrimmedValue(action.payload)
      });
    case 'SET_COMPANY_NAME_WITH_ADDRESS':
    const address = (action.payload || {}).address;
      const splittedAddress = splitAddress(address);
      return Object.assign({}, state, {
        companyName: (action.payload || {}).companyName,
        address,
        street: splittedAddress.street,
        postalCode: splittedAddress.postalCode,
        city: splittedAddress.city,
        country: (action.payload || {}).country,
        phone: (action.payload || {}).phone,
      });
    case 'SET_ADDRESS':
      return Object.assign({}, state, {
        address: getTrimmedValue(action.payload)
      });
    case 'SET_STREET':
      return Object.assign({}, state, {
        street: getTrimmedValue(action.payload)
      });
    case 'SET_POSTAL_CODE':
      return Object.assign({}, state, {
        postalCode: getTrimmedValue(action.payload)
      });
    case 'SET_CITY':
      return Object.assign({}, state, {
        city: getTrimmedValue(action.payload)
      });
    case 'SET_PHONE':
      return Object.assign({}, state, {
        phone: getTrimmedValue(action.payload)
      });
    case 'SET_IBAN':
      return Object.assign({}, state, {
        iban: getTrimmedValue(action.payload)
      });
    case 'SET_BIC':
      return Object.assign({}, state, {
        bic: getTrimmedValue(action.payload)
      });
    case 'SET_PARTNER_EMAIL':
      return Object.assign({}, state, {
        partnerEmail: getTrimmedValue(action.payload)
      });
    case 'SET_VOUCHER_CODE':
      return Object.assign({}, state, {
        voucherCode: getTrimmedValue(action.payload)
      });
    case 'SET_ORDER_CODE':
      return Object.assign({}, state, {
        orderCode: getTrimmedValue(action.payload)
      });
    case 'SET_STORE_MANAGEMENT':
      return Object.assign({}, state, {
        storeManagement: action.payload
      });
    case 'SHOW_CONFIRM_REGISTRATION':
      return Object.assign({}, state, {
        showConfirmRegistration: true
      });
    case 'SHOW_CONFIRM_CUSTOMER_REGISTRATION':
      return Object.assign({}, state, {
        showConfirmCustomerRegistration: true
      });
    default:
      return state;
  }
}

const store = createStore(reducer);

export default store;