import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from './components/Loader';

const RegistrationWizard = lazy(() => import('./wizards/RegistrationWizard'));
const SubscriptionWizard = lazy(() => import('./wizards/SubscriptionWizard'));
const PartnerWizard = lazy(() => import('./wizards/PartnerWizard'));
const SumupWizard = lazy(() => import('./wizards/SumupWizard'));
const CASWizard = lazy(() => import('./wizards/CASWizard'));
const EulaIndex = lazy(() => import('./docs/Eula'));
const GeneralConditionsIndex = lazy(() => import('./docs/GeneralConditions'));
const ImprintIndex = lazy(() => import('./docs/Imprint'));
const PrivacyPolicyIndex = lazy(() => import('./docs/PrivacyPolicy'));

const search = window.location.search;
const searchParams = new URLSearchParams(search);
const token = searchParams.get('t');
const partnerId = searchParams.get('p');


class Routing extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    
    this.props.dispatch({type: 'SET_PARTNER_ID', payload: partnerId});
    this.props.dispatch({type: 'USE_PROTECTED_INPUT_FIELD', payload: !!partnerId});
  }
  
  render() {    
    return (
      <Router>
        <Suspense fallback={<div className="spinner-centered"><Spinner /></div>}>
          <Switch>
            <Route path = "*/generalConditions" component = {GeneralConditionsIndex} />
            <Route path = "*/eula" component = {EulaIndex} />
            <Route path = "*/imprint" component = {ImprintIndex} />
            <Route path = "*/privacyPolicy" component = {PrivacyPolicyIndex} />
            <Route path = "*/subscription" component = {SubscriptionWizard} />
            <Route path = "*/registration" component = {!!token ? SubscriptionWizard : RegistrationWizard} />
            <Route path = "*/partner-portal" component = {PartnerWizard} />
            <Route path = "*/sumup" component = {SumupWizard} />
            <Route path = "*/cas" component = {CASWizard} />
            <Route path = "*/" component = {!!token ? SubscriptionWizard : PartnerWizard} />
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(undefined, mapDispatchToProps)(Routing);