import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './i18n';
import Router from './router';
import store from './store/index';
// import reportWebVitals from './reportWebVitals';

import './css/bootstrap.min.css';
import './css/color-scheme-1.scss';
import './css/common-styles.scss';
// import './css/phoenix-styles.scss';
import './css/smartpos-styles.scss';


window.store = store;

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
